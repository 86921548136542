import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Qs from 'qs';

import { FetchInterruption, Page } from 'components';
import { PhotoSlider } from '.';

import { ReactComponent as ArrowIcon } from './assets/arrowIcon.svg';

import routes from 'routes';

import connect from 'connect';

const Wrapper = styled.div``;

const Photos = styled(PhotoSlider)`
  @media (min-width: 768px) {
    height: calc(100vh - 14rem);
  }
`;

const BackIcon = styled(ArrowIcon)``;

const BackButton = styled(Page.BackButton)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 2rem;
      background: ${theme.colors.passive.light};
      width: 100%;
      z-index: 100;
    }
  `}

  ${BackIcon} {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const ProtfolioView = ({ className, history, location, t }) => {
  const { id } = Qs.parse(location.search, { ignoreQueryPrefix: true });

  const [isFetched, setFetchedStatus] = useState(false);
  const [items, setItems] = useState(null);

  const handleSlideChange = (slide) => {
    const { id } = slide;
    history.push({ search: `?id=${id}` });
  };

  useEffect(
    () => {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = null;
      };
    },
    [],
  );

  useEffect(
    () => {
      connect.get('/api/portfolio').then(({ data }) => {
        const { photos } = data.data;

        setItems(photos);
        setFetchedStatus(true);
      });
    },
    [],
  );

  const renderHeader = () => {
    const photo = items?.find(item => item.id === id);

    if (!photo) return null;

    return (
      <BackButton to={routes.home.getPath()}>
        {t('UI.Back')}
      </BackButton>
    );
  };

  if (!id) {
    return (
      <Redirect to={routes.home.getPath()} />
    );
  }

  return (
    <Page.Container>
      <Page.Header>
        {renderHeader()}
      </Page.Header>

      <Page.Content>
        <Wrapper className={className}>
          {isFetched && (
            <Photos
              items={items}
              slide={id}
              onChange={handleSlideChange}
            />
          )}
        </Wrapper>
      </Page.Content>

      <FetchInterruption isHidden={isFetched} />
    </Page.Container>
  );
};

ProtfolioView.propTypes = {
  className: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ProtfolioView);
