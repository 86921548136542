import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Image } from 'components';

const Wrapper = styled.div``;

const PhotoImage = styled(Image)`
  width: 100%;
  height: auto;
`;

const SinglePhoto = ({ className, image, title, onClick }) => (
  <Wrapper
    className={className}
    onClick={onClick && onClick}
  >
    <PhotoImage
      src={image.thumbSrc}
      alt={title}
    />
  </Wrapper>
);

SinglePhoto.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

SinglePhoto.defaultProps = {
  title: 'Photo',
};

export default SinglePhoto;
