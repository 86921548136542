import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ChildrenPropType } from 'types';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = ({ children, className }) => (
  <Wrapper className={className}>
    {children}
  </Wrapper>
);

Content.propTypes = {
  children: ChildrenPropType,
  className: PropTypes.string
};

export default Content;
