import MockAdapter from 'axios-mock-adapter';

import { portfolioData } from './fixtures';

import { deepClone } from 'utils/object';

function staticImage(src) {
  return require(`./fixtures/images/portfolio/${src}`);
};

export function applyMocks(instance) {
  const mock = new MockAdapter(instance, { delayResponse: 500 });

  mock
    .onGet('/api/portfolio').reply(({ params = {} }) => new Promise(
      (resolve) => {
        const limit = 20;
        const page = params.page || 1;
        const total = Math.ceil(portfolioData.data.length / limit);
        const data = deepClone(portfolioData);

        data.data.photos = data.data.photos.map(item => ({
          ...item,
          image: {
            src: staticImage(item.image.src),
            thumbSrc: staticImage(item.image.thumbSrc),
          },
        }));

        if (page > 1) {
          data.data = data.data.slice(limit * (page - 1), limit * page);
        }

        data.meta = {
          limit,
          total,
          page,
        };

        resolve([ 200, data ]);
      }
    ))

    .onAny().passThrough();
}
