import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styled, { css, withTheme } from 'styled-components';

import routes from 'routes';

const Wrapper = styled.div``;

const Title = styled.div`
  ${({ theme }) => css`
    margin: 3rem 0;
    font-size: 5rem;
    line-height: 1.5em;
    text-align: center;
    color: ${theme.colors.text.primary};
  `}
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
`;

const HomeLink = styled(Link)`
  ${({ theme }) => css`
    font-size: 2rem;
    color: ${theme.colors.active.primary};

    @media (min-width: 768px) {
      font-size: 2.5rem;
      transition: color 0.25s;

      :hover {
        color: ${theme.colors.text.active};
      }
    }
  `}
`;

const NotFoundView = ({ t }) => {
  return (
    <Wrapper>
      <Title>
        {t('NotFound.Title')}
      </Title>
      
      <Controls>
        <HomeLink to={routes.home.getPath()}>
          {t('NotFound.HomeLink')}
        </HomeLink>
      </Controls>
    </Wrapper>
  );
};

export default withTheme(withTranslation()(NotFoundView));
