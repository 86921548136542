import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ChildrenPropType } from 'types';

const Wrapper = styled.div``;

const Track = ({
  className,
  children,
  onPointerEnter,
  onPointerLeave,
  onPointerMove,
}) => {
  const handleEnter = (event) => {
    if (onPointerEnter) {
      onPointerEnter(event);
    }
  };

  const handleLeave = (event) => {
    if (onPointerLeave) {
      onPointerLeave(event);
    }
  };

  const handleMove = (event) => {
    if (onPointerMove) {
      onPointerMove(event);
    }
  };

  return (
    <Wrapper
      className={className}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      onMouseMove={handleMove}
    >
      {children}
    </Wrapper>
  );
};

Track.propTypes = {
  className: PropTypes.string,
  children: ChildrenPropType.isRequired,
  onPointerEnter: PropTypes.func,
  onPointerLeave: PropTypes.func,
  onPointerMove: PropTypes.func,
};

export default Track;
