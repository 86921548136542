import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${theme.text.flexible}
  `}
`;

const LocalTime = ({ className }) => {
  const [time, setTime] = useState(currentTime());

  useEffect(
    () => {
      const timer = setTimeout(
        () => {
          setTime(currentTime());
        },
        1000,
      );

      return () => {
        clearTimeout(timer);
      };
    },
    [time],
  );

  return (
    <Wrapper className={className}>
      {time}
    </Wrapper>
  );
};

LocalTime.propTypes = {
  className: PropTypes.string,
};

export default LocalTime;

function currentTime() {
  return [
    new Date().getHours(),
    `${new Date().getMinutes()}`.padStart(2, '0'),
    `${new Date().getSeconds()}`.padStart(2, '0'),
  ].join(':');
}
