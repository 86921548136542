export default {
  translation: {
    List: {
      Back: 'Маленькие картинки',
    },
    Weather: {
      City: 'В Москве',
      Temperature: `{{temperature}} ℃`,
    },
    NotFound: {
      Title: 'Страница не найдена',
      HomeLink: 'Home',
    },
    UI: {
      Back: 'Назад',
    },
  },
};
