import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const SLIDE_CHANGE_ANIMATION_DURATION = 500;
const TOUCH_START_WIDTH_FACTOR = 0.3;
const ALLOWED_Y_TOUCH_DIFF = 64;

const Item = styled.div`
  ${({ isCollapsed, duration, theme, isSlidesTransition }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100%;
    background: ${theme.colors.passive.light};

    ${isSlidesTransition && css`
      transition: width ${duration}ms;
    `}

    @media (min-width: 768px) {
      align-items: flex-start;
    }

    ${isCollapsed && css`
      width: 0;
    `}

    .lazy-load-image-background {
      height: 100%;
    }
  `}
`;

const Items = styled.div`
  ${({ isReversedDirection }) => css`
    display: flex;
    position: relative;
    left: -100%;
    width: 300%;
    height: 100%;

    ${isReversedDirection && css`
      justify-content: flex-end;
    `}
  `}
`;

const PhotoImage = styled.img`
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    height: calc(100% - 4rem);
  }
`;

const SlideControl = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 0;
  width: 50%;
  height: 100%;

  @media (max-width: 767px) {
    display: none;
  }
`;

const PrevSlideControl = styled(SlideControl)`
  justify-content: flex-start;
  left: 0;
  padding-left: 10%;
`;

const NextSlideControl = styled(SlideControl)`
  justify-content: flex-end;
  right: 0;
  padding-right: 10%;
`;

const Photos = ({
  currentSlideIndex,
  isLastSlideSelected,
  duration,
  items,
  onChange,
  onDirectionChange,
  onPhotoChange,
  onSlideNextBefore,
  onSlideNextAfter,
  onSlidePrevBefore,
  onSlidePrevAfter,
}) => {
  const [collapsedItemIndex, setCollapsedItemIndex] = useState(null);
  const [isReversedDirection, setReversedDirection] = useState(false);
  const [isSlidesTransition, setSlidesTransition] = useState(false);

  const [pointerPosition, setPointerPosition] = useState();

  const photoNodeRef = useCallback(
    (node) => {
      if (node) {
        setTimeout(
          () => {
            onPhotoChange({ node });
          },
          20,
        );
      }
    },
    [],
  );

  const handleSlideChange = (slideIndex) => {
    setCollapsedItemIndex(null);
    onChange(slideIndex);
  };

  const handleSlidePrevChange = () => {
    if (items[0]) {
      setCollapsedItemIndex(2);
      setReversedDirection(true);
      setSlidesTransition(true);

      if (onSlidePrevBefore) {
        onSlidePrevBefore();
      }
      
      setTimeout(
        () => {
          if (onSlidePrevAfter) {
            setSlidesTransition(false);
            handleSlideChange(currentSlideIndex - 1);
            onSlidePrevAfter();
          }
        },
        duration,
      );
    }
  };

  const handleSlideNextChange = () => {
    if (items[2]) {
      setCollapsedItemIndex(0);
      setReversedDirection(false);
      setSlidesTransition(true);

      if (onSlideNextBefore) {
        onSlideNextBefore();
      }

      setTimeout(
        () => {
          if (onSlideNextAfter) {
            setSlidesTransition(false);
            handleSlideChange(currentSlideIndex + 1);
            onSlideNextAfter();
          }
        },
        duration,
      );
    }
  };

  const handleSlidePrevHover = () => {
    onDirectionChange('prev');
  };

  const handleSlideNextHover = () => {
    onDirectionChange('next');
  };

  const handleTouchStart = (event) => {
    const { clientX, clientY } = event.targetTouches[0];
    setPointerPosition([clientX, clientY]);
  };

  const handleTouchEnd = (event) => {
    const { clientX, clientY } = event.changedTouches[0];
    setPointerPosition([clientX, clientY]);

    const xPositionDiff = window.innerWidth * TOUCH_START_WIDTH_FACTOR;
    const isSwipeable =
      Math.abs(pointerPosition[1] - clientY) <= ALLOWED_Y_TOUCH_DIFF
        && Math.abs(pointerPosition[0] - clientX) >= xPositionDiff;
    
    if (isSwipeable) {
      if ((clientX > pointerPosition[0]) && items[0]) {
        handleSlidePrevChange();
      }
  
      if ((clientX < pointerPosition[0]) && items[2]) {
        handleSlideNextChange();
      }
    }

    setPointerPosition(null);
  };

  const renderItems = () => items
    .filter(item => item)
    .map(({ id, image, title }, index) => (
      <Item
        key={id}
        isCollapsed={(!!items[0] && !isLastSlideSelected) && collapsedItemIndex === index}
        isSlidesTransition={isSlidesTransition}
        duration={duration}
      >
        {index === 1
          ? (
            <PhotoImage
              ref={photoNodeRef}
              src={image.src}
              alt={title}
            />
          )
          : (
            <PhotoImage
              src={image.src}
              alt={title}
            />
          )
        }
      </Item>
    ));

  return (
    <>
      <Items
        isReversedDirection={isReversedDirection}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {!items[0] && (
          <Item
            isCollapsed={collapsedItemIndex === 0}
            duration={duration}
          />
        )}

        {renderItems()}

        {isLastSlideSelected && (
          <Item
            isCollapsed={collapsedItemIndex === 2}
            duration={duration}
          />
        )}
      </Items>

      <PrevSlideControl
        onClick={handleSlidePrevChange}
        onMouseEnter={handleSlidePrevHover}
      />

      <NextSlideControl
        onClick={handleSlideNextChange}
        onMouseEnter={handleSlideNextHover}
      />
    </>
  );
};

Photos.propTypes = {
  currentSlideIndex: PropTypes.number,
  isLastSlideSelected: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  onDirectionChange: PropTypes.func.isRequired,
  onPhotoChange: PropTypes.func.isRequired,
  onSlideNextBefore: PropTypes.func,
  onSlideNextAfter: PropTypes.func,
  onSlidePrevBefore: PropTypes.func,
  onSlidePrevAfter: PropTypes.func,
};

Photos.defaultProps = {
  duration: SLIDE_CHANGE_ANIMATION_DURATION,
  isReversedDirection: false,
};

export default Photos;
