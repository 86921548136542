import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ChildrenPropType } from 'types';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem;

  @media (min-width: 640px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }

  @media (min-width: 768px) {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4rem;
  }
`;

const PhotosGrid = ({ children, className }) => (
  <Wrapper className={className}>
    {children}
  </Wrapper>
);

PhotosGrid.propTypes = {
  className: PropTypes.string,
  children: ChildrenPropType.isRequired,
};

export default PhotosGrid;
