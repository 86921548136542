import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ isDisplay }) => css`
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 2rem;

    ${!isDisplay && css`
      display: none;
    `}
  `}

  @media (min-width: 768px) {
    align-items: center;
    width: 50%;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }
`;

const Text = styled.div`
  position: relative;
  font-size: 2.5rem;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const TextFiller = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;
  overflow: hidden;

  @media (min-width: 768px) {
    top: 0;
    bottom: auto;
  }
`;

const TextFillerInner = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;

  @media (min-width: 768px) {
    top: 0;
    bottom: auto;
  }
`;

const AlbumTitle = ({
  className,
  color,
  isDisplay,
  overlay,
  title,
}) => {
  let titleNode = null;

  const [titleWidth, setTitleWidth] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  const [fillerWidth, setFillerWidth] = useState(0);
  const [fillerHeight, setFillerHeight] = useState(0);

  const getTitleNodeRef = (node) => {
    titleNode = node;
  };

  const handleOverlayChange = () => {
    const {
      left: titleLeft,
      top: titleTop,
      width: titleWidth,
      height: titleHeight,
    } = titleNode.getBoundingClientRect();

    if (overlay.left <= titleLeft + titleWidth) {
      setTitleWidth(titleWidth);
      setTitleHeight(titleHeight);
      setFillerWidth(titleWidth + titleLeft - overlay.left);
      setFillerHeight(titleHeight + titleTop - overlay.top);
    }
  };

  const titleNodeRef = useCallback(
    (node) => {
      if (node) {
        getTitleNodeRef(node);
        handleOverlayChange();
      }
    },
    [],
  );

  useEffect(
    () => {
      window.addEventListener('resize', handleOverlayChange);

      return () => {
        window.removeEventListener('resize', handleOverlayChange);
      };
    },
    [],
  );

  useEffect(
    () => {
      if (overlay && titleNode) {
        handleOverlayChange();
      }
    },
    [
      titleNode,
      overlay,
    ],
  );

  return (
    <Wrapper
      className={className}
      isDisplay={isDisplay}
    >
      <Text ref={titleNodeRef}>
        {title}

        <TextFiller style={{
          color,
          width: fillerWidth,
          height: fillerHeight,
        }}>
          <TextFillerInner style={{
            width: titleWidth,
            height: titleHeight,
          }}>
            {title}
          </TextFillerInner>
        </TextFiller>
      </Text>
    </Wrapper>
  );
};

AlbumTitle.propTypes = {
  className: PropTypes.string,
  isDisplay: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
  overlay: PropTypes.object.isRequired,
};

AlbumTitle.defaultProps = {
  isDisplay: false,
};

export default AlbumTitle;
