import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
const city = 'Moscow,RU';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${theme.text.flexible}
  `}
`;

const Weather = ({ className, t }) => {
  const [weather, setWeather] = useState(null);

  useEffect(
    () => {
      const currentTime = Date.now();
      const savedTime = +sessionStorage.getItem('WEATHER_TS');
      const isOutdated = currentTime - savedTime > 30 * 60 * 1000;

      if (isOutdated) {
        axios.get(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`)
          .then(({ data }) => {
            sessionStorage.setItem('WEATHER_DATA', JSON.stringify(data));
            sessionStorage.setItem('WEATHER_TS', Date.now());

            setWeather(data);
          });
      }

      else {
        const data = JSON.parse(sessionStorage.getItem('WEATHER_DATA'));
        setWeather(data);
      }
    },
    [],
  );

  if (!weather) return null;

  const renderTemperature = () => {
    const value = Math.round(weather.main.temp);
    
    if (value > 0) {
      return `+${value}`;
    }

    if (value < 0) {
      return String(value).replace('-', '–');
    }

    return 0;
  };

  const renderFallout = () => {
    const type = weather.weather[0].main.toLowerCase();

    if (type === 'clouds') {
      return 'облачно';
    }

    if (type === 'snow') {
      return 'снег';
    }

    return null;
  };

  return (
    <Wrapper className={className}>
      {t('Weather.City')}
      {' '}
      <nobr>
        {renderFallout()} {t('Weather.Temperature', { temperature: renderTemperature() })}
      </nobr>
    </Wrapper>
  );
};

Weather.propTypes = {
  className: PropTypes.string,
};

export default withTranslation()(Weather);
