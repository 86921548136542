import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Image } from 'components';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 2rem;

  > *:nth-child(1) {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  > *:nth-child(2) {
    position: absolute;
    z-index: 1;
    left: 1rem;
    top: 1rem;

    @media (min-width: 768px) {
      left: 2rem;
      top: 2rem;
    }
  }

  > *:nth-child(3) {
    position: relative;
    left: 2rem;
    top: 2rem;

    @media (min-width: 768px) {
      left: 4rem;
      top: 4rem;
    }
  }
`;

const PhotoImage = styled(Image)`
  width: calc(80% - 2rem);
  height: auto;
`;

const PhotosCollection = ({ className, images, title, onClick }) => (
  <Wrapper
    className={className}
    onClick={onClick && onClick}
  >
    {images.filter((item, index) => index <= 2).map((image, index) => (
      <PhotoImage
        key={index}
        src={image.thumbSrc}
        alt={title}
      />
    ))}
  </Wrapper>
);

PhotosCollection.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

PhotosCollection.defaultProps = {
  title: 'Photo',
};

export default PhotosCollection;
