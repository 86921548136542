import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import routes from 'routes';

import { ChildrenPropType } from 'types';

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10rem;
  padding: 2rem;

  @media (min-width: 768px) {
    align-items: center;
    height: 14rem;
    padding: 4rem 3rem;
  }
`;

const Logo = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    ${theme.text.flexible}
  `}
`;

const Content = styled.div``;

const Header = ({ className, children, location }) => {
  const isHome = location.pathname === routes.home.path;

  return (
    <Wrapper className={className}>
      <Logo
        as={!isHome && Link}
        to={!isHome ? routes.home.path : undefined}
      >
        Маша Ростовская, 22, Москва
      </Logo>

      <Content>
        {children}
      </Content>
    </Wrapper>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  children: ChildrenPropType,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(Header);
