import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactRouterPropTypes from 'react-router-prop-types';

import {
  AuthorEmail, FetchInterruption, LocalTime, PhotosGrid, SinglePhoto, Page, PhotosCollection, Weather,
} from 'components';

import connect from 'connect';
import routes from 'routes';

const Wrapper = styled.div`
  padding: 0 1.5rem;

  @media (min-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

const Photo = styled(SinglePhoto)`
  ${({ order }) => css`
    cursor: pointer;
    order: ${order};
  `}
`;

const Photos = styled(PhotosCollection)`
  ${({ order }) => css`
    cursor: pointer;
    order: ${order};
  `}
`;

const DesignerEmail = styled(AuthorEmail)`
  order: 9999999;
`;

const CurrentTime = styled(LocalTime)`
  @media (min-width: 768px) {
    order: 3;
  }
`;

const CurrentWeather = styled(Weather)`
  order: 2;

  @media (min-width: 768px) {
    order: 3;
  }
`;

const HomeView = ({ className, history }) => {
  const [isFetched, setFetchedStatus] = useState(false);
  const [items, setItems] = useState(null);

  const handlePhotoSelect = (id) => {
    history.push(`${routes.portfolio.path}?id=${id}`);
  };

  useEffect(
    () => {
      connect.get('/api/portfolio').then(({ data }) => {
        const photos = data.data.photos.reduce(
          (result, item, index, items) => {
            if (item.album) {
              if (items[index - 1].album?.id === item.album.id) {
                return result;
              }

              item.images = items.filter(({ album }) => album?.id === item.album.id).map(({ image }) => image);
            }

            return [
              ...result,
              item,
            ];
          },
          [],
        );

        setItems(photos);
        setFetchedStatus(true);
      });
    },
    [],
  );

  const renderPhotos = () => {
    return items.map(({ id, image, images, title }, index) => {
      if (images) {
        return (
          <Photos
            key={id}
            images={images}
            title={title}
            order={index}
            onClick={() => handlePhotoSelect(id)}
          />
        );
      }

      return (
        <Photo
          key={id}
          image={image}
          title={title}
          order={index}
          onClick={() => handlePhotoSelect(id)}
        />
      );
    });
  };

  return (
    <Page.Container>
      <Page.Header />

      <Page.Content>
        <Wrapper className={className}>
          {isFetched && (
            <PhotosGrid>
              {renderPhotos()}
        
              <CurrentTime />
        
              <CurrentWeather />

              <DesignerEmail />
            </PhotosGrid>
          )}
        </Wrapper>
      </Page.Content>

      <FetchInterruption isHidden={isFetched} />
    </Page.Container>
  );
};

HomeView.propTypes = {
  className: PropTypes.string,
  history: ReactRouterPropTypes.browsersHistory,
};

export default HomeView;
