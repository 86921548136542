import { css } from 'styled-components';

export default {
  colors: {
    active: {
      primary: '#0000ff',
    },
    passive: {
      light: '#ffffff',
      secondary: '#cccccc',
    },
    text: {
      primary: '#000000',
    },
    preloader: {
      primary: '#777777',
    }
  },
  text: {
    flexible: css`
      font-size: 2.5rem;
      text-transform: uppercase;

      @media (min-width: 768px) {
        font-size: 3.5rem;
      }
  
      @media (min-width: 1200px) {
        font-size: 4.5rem;
      }
    `,
  },
  maxWidth: '128rem',
};
