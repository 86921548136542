import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Preloader } from 'components';

const Wrapper = styled.div`
  ${({ theme, isHidden }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${theme.colors.passive.light};
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.5s;

    ${isHidden && css`
      opacity: 0;
      pointer-events: none;
    `}
  `}
`;

const FetchInterruption = ({ className, isHidden }) => (
  <Wrapper
    className={className}
    isHidden={isHidden}
  >
    <Preloader />
  </Wrapper>
);

FetchInterruption.propTypes = {
  className: PropTypes.string,
  isHidden: PropTypes.bool,
};

FetchInterruption.defaultProps = {
  isHidden: false,
};

export default FetchInterruption;
