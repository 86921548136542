import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const EMAIL = 'mglbv@yandex.ru';

const Wrapper = styled.a`
  ${({ theme }) => css`
    padding: 2rem 0;
    ${theme.text.flexible}
    color: ${theme.colors.text.primary};
  `}
`;

const AuthorEmail = ({ className }) => {
  return (
    <Wrapper
      className={className}
      href={`mailto:${EMAIL}`}
    >
      {EMAIL}
    </Wrapper>
  );
};

AuthorEmail.propTypes = {
  className: PropTypes.string,
};

export default AuthorEmail;
