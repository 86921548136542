import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ChildrenPropType } from 'types';

const Wrapper = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    ${theme.text.flexible}
  `}
`;

const BackButton = ({ className, children, to }) => (
  <Wrapper
    className={className}
    to={to}
  >
    {children}
  </Wrapper>
);

BackButton.propTypes = {
  className: PropTypes.string,
  children: ChildrenPropType.isRequired,
  to: PropTypes.string.isRequired,
};

export default BackButton;
