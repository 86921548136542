import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Image = styled.img`
  width: 100%;
`;

const Wrapper = styled.span`
  ${({ isRevealed, theme }) => css`
    display: inline-block;
    position: relative;

    ${Image} {
      opacity: 0;
      transition: opacity 0.5s;

      ${isRevealed && css`
        opacity: 1;
      `}
    }

    ${!isRevealed && css`
      background: ${theme.colors.passive.secondary};
      opacity: 0.5;
    `}
  `}
`;

const LazyLoadImage = ({ className, src, width, height, alt }) => {
  const [isRevealed, setRevealed] = useState(false);

  const handleImageLoaded = () => {
    setRevealed(true);
  };

  return (
    <Wrapper
      className={className}
      width={width}
      height={height}
      isRevealed={isRevealed}
    >
      <Image
        src={src}
        width={width}
        height={height}
        alt={alt}
        onLoad={handleImageLoaded}
      />
    </Wrapper>
  );
};

LazyLoadImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
};

export default LazyLoadImage;
