import PropTypes from 'prop-types';

export const ChildrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.arrayOf(PropTypes.element),
  PropTypes.string,
  PropTypes.number,
  PropTypes.node,
  PropTypes.element,
]);

export const ImagePropType = PropTypes.shape({
  src: PropTypes.string,
  thumbSrc: PropTypes.string,
});

export const PortfolioItemPropType = PropTypes.shape({
  id: PropTypes.string,
  image: ImagePropType,
  title: PropTypes.string,
});
