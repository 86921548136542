import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from './assets/arrowIcon.svg';

const CURSOR_SIZE = 56;
const CURSOR_Y_OFFSET = 112;

const Icon = styled(ArrowIcon)`
  width: ${CURSOR_SIZE}px;
  height: ${CURSOR_SIZE}px;
`;

const CursorInner = styled.div`
  ${({ isReversedDirection, color = '#ffffff' }) => css`
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;

    ${Icon} {
      position: absolute;
      top: 0;

      path {
        fill: ${color};
      }
    }

    ${isReversedDirection && css`
      left: 0;

      ${Icon} {
        left: 0;
      }
    `}

    ${!isReversedDirection && css`
      right: 0;

      ${Icon} {
        right: 0;
      }
    `}
  `}
`;

const Wrapper = styled.div`
  ${({ isDisplay, isNextDirection }) => css`
    position: absolute;
    z-index: 100;
    width: 7rem;
    height: 7rem;
    margin-top: -3.5rem;
    margin-left: -3.5rem;
    pointer-events: none;

    ${!isDisplay && css`
      display: none;
    `}

    ${Icon} {
      ${isNextDirection && css`
        transform: rotate(180deg);
      `}
    }
  `}
`;

const Cursor = React.forwardRef(({
  className,
  color,
  isDisplay,
  isNextDirection,
  position,
  overlay,
}, ref) => {
  const [cursorPosition, setCursorPosition] = useState([0, 0]);
  const [cursorWidthFill, setCursorWidthFill] = useState(0);
  const [cursorHeightFill, setCursorHeightFill] = useState(0);

  useEffect(
    () => {
      setTimeout(
        () => {
          const {
            left,
            width: photoWidth,
            height: photoHeight,
          } = overlay;
    
          const cursorX = position[0];
          const cursorY = position[1] - CURSOR_Y_OFFSET;
          const photoSideOffset = window.innerWidth - photoWidth > 0 ? Math.round((window.innerWidth - photoWidth) / 2) : 0;
    
          const width = isNextDirection
            ? left + photoWidth - cursorX + CURSOR_SIZE / 2
            : CURSOR_SIZE - (photoSideOffset - cursorX + CURSOR_SIZE / 2);
  
          const height = photoHeight - cursorY + CURSOR_SIZE / 2;
    
          if (width > CURSOR_SIZE) {
            setCursorWidthFill(CURSOR_SIZE);
          }
    
          if (width > 0 && width < CURSOR_SIZE) {
            setCursorWidthFill(width);
          }
    
          if (width <= 0) {
            setCursorWidthFill(0);
          }
    
          if (height > CURSOR_SIZE) {
            setCursorHeightFill(CURSOR_SIZE);
          }
    
          if (height > 0 && height < CURSOR_SIZE) {
            setCursorHeightFill(height);
          }
    
          if (height <= 0) {
            setCursorHeightFill(0);
          }
    
          setCursorPosition([
            cursorX,
            cursorY,
          ]);
        },
        0,
      );
    },
    [
      position,
      overlay,
      isNextDirection,
    ]
  );

  if (window.innerWidth < 768) return null;

  return (
    <Wrapper
      ref={ref}
      className={className}
      isDisplay={isDisplay}
      isNextDirection={isNextDirection}
      style={{
        transform: `translate(${cursorPosition[0]}px, ${cursorPosition[1]}px)`,
      }}
    >
      <Icon />

      <CursorInner
        isReversedDirection={isNextDirection}
        color={color}
        style={{
          height: cursorHeightFill,
          width: cursorWidthFill,
        }}
      >
        <Icon />
      </CursorInner>
    </Wrapper>
  );
});

Cursor.propTypes = {
  className: PropTypes.string,
  isDisplay: PropTypes.bool,
  isNextDirection: PropTypes.bool,
  color: PropTypes.string,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  overlay: PropTypes.object.isRequired,
};

Cursor.defaultProps = {
  isDisplay: false,
  isNextDirection: false,
};

export default Cursor;
