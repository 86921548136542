import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  margin: auto;
  width: 5rem;
  height: 5rem;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 5rem;
  animation-name: rotate;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

const Preloader = ({ className }) => (
  <Wrapper className={className}>
    <span
      role="img"
      aria-label="preloader"
    >
      🦐
    </span>
  </Wrapper>
);

Preloader.propTypes = {
  className: PropTypes.string
};

export default Preloader;
