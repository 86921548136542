import { BackButton, Container, Content, Header } from '.';

const Page = {
  BackButton,
  Container,
  Content,
  Header,
};

export default Page;
