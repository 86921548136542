import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AlbumTitle, DirectionMarker, Photos } from '.';

const Cursor = styled(DirectionMarker.Cursor)``;

const Wrapper = styled(DirectionMarker.Track)`
  position: relative;
  height: 100%;
  padding: 4rem 0 0;
  overflow: hidden;
  cursor: none;
  user-select: none;
`;

const PhotoSlider = ({ className, items, slide, onChange }) => {
  let photoNode = null;
  const [currentSlideIndex, setCurrentSlideIndex] = useState();  
  const [photoItems, setPhotoItems] = useState([]);

  const [dominantColor, setDominantColor] = useState('#ffffff');

  const [isAlbumTitleDisplay, setAlbumTitleDisplay] = useState(true);

  const [pointerPosition, setPointerPosition] = useState([0, 0]);
  const [isCursorDisplay, setCursorDisplay] = useState(false);
  const [isCursorNextDirection, setCursorNextDirection] = useState(false);

  const [photoOverlay, setPhotoOverlay] = useState(null);

  const handleWrapperEnter = () => {
    setCursorDisplay(true);
  };

  const handleWrapperLeave = () => {
    setCursorDisplay(false);
  };

  const handleWrapperCursorMove = (event) => {
    setPointerPosition([event.clientX, event.clientY]);
  };

  const handleSlideChange = (slideIndex) => {
    onChange(items[slideIndex]);
  };

  const handleDirectionChange = (direction) => {
    if (direction === 'next') {
      setCursorNextDirection(true);
    }
    else {
      setCursorNextDirection(false);
    }
  };

  const handleOverlayChange = () => {
    const { left, top, width, height } = photoNode.getBoundingClientRect();
    setPhotoOverlay({ left, top, width, height });
  };

  const handlePhotoNodeChange = ({ node }) => {
    photoNode = node;
    handleOverlayChange();
  };

  const handleSlidePrevBefore = () => {
    setCursorDisplay(false);
    setAlbumTitleDisplay(false);
  };

  const handleSlidePrevAfter = () => {
    setTimeout(
      () => {
        setAlbumTitleDisplay(true);
      },
      100,
    );
    setCursorDisplay(true);
  };

  const handleSlideNextBefore = () => {
    setCursorDisplay(false);
    setAlbumTitleDisplay(false);
  };

  const handleSlideNextAfter = () => {
    setTimeout(
      () => {
        setAlbumTitleDisplay(true);
      },
      100,
    );
    setCursorDisplay(true);
  };

  useEffect(
    () => {
      window.addEventListener('resize', handleOverlayChange);

      return () => {
        window.removeEventListener('resize', handleOverlayChange);
      };
    },
    [],
  );

  useEffect(
    () => {
      const slideIndex = items.map(({ id }) => id).indexOf(slide);
      setCurrentSlideIndex(slideIndex !== -1 ? slideIndex : 0);

      const initialSlideIndex = items.map(({ id }) => id).indexOf(slide);

      setPhotoItems([
        items[initialSlideIndex - 1],
        items[initialSlideIndex],
        items[initialSlideIndex + 1],
      ]);

      setDominantColor(items[slideIndex !== -1 ? slideIndex : 0].dominantColor);
    },
    [
      slide,
      items,
    ],
  );

  return (
    <Wrapper
      className={className}
      onPointerEnter={handleWrapperEnter}
      onPointerLeave={handleWrapperLeave}
      onPointerMove={handleWrapperCursorMove}
    >
      {photoOverlay && (
        <Cursor
          color={dominantColor}
          isDisplay={isCursorDisplay}
          isNextDirection={isCursorNextDirection}
          position={pointerPosition}
          overlay={photoOverlay}
        />
      )}

      <Photos
        currentSlideIndex={currentSlideIndex}
        isLastSlideSelected={currentSlideIndex === items.length - 1}
        items={photoItems}
        onChange={handleSlideChange}
        onDirectionChange={handleDirectionChange}
        onPhotoChange={handlePhotoNodeChange}
        onSlidePrevBefore={handleSlidePrevBefore}
        onSlidePrevAfter={handleSlidePrevAfter}
        onSlideNextBefore={handleSlideNextBefore}
        onSlideNextAfter={handleSlideNextAfter}
      />

      {(photoItems[1]?.album && isAlbumTitleDisplay && photoOverlay) && (
        <AlbumTitle
          isDisplay={isAlbumTitleDisplay}
          color={dominantColor}
          overlay={photoOverlay}
          title={photoItems[1].album.title}
        />
      )}
    </Wrapper>
  );
};

PhotoSlider.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.object.isRequired,
    title: PropTypes.string,
  })).isRequired,
  slide: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default PhotoSlider;
